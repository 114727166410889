import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  Input,
  TemplateRef,
} from '@angular/core';
import { MediaDeviceErrorCode } from '@freelancer/media-devices';
import { HeadingType } from '@freelancer/ui/heading';
import { TextSize } from '@freelancer/ui/text';
import { FaceFilterType } from '../face-filter';
import { ModalRef } from '../modal/modal-ref';

@Component({
  template: `
    <ng-container *flModalTitle>
      {{ modalTitle }}
    </ng-container>

    <div flTrackingSection="AppWebcamVideo">
      <fl-heading
        *ngIf="this.modalHeader"
        class="VideoRecordModal-header"
        [flMarginBottom]="'small'"
        [headingType]="HeadingType.H4"
        [size]="TextSize.MID"
      >
        {{ modalHeader }}
      </fl-heading>

      <div
        *ngIf="this.content"
        class="VideoRecordModal-content"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>

      <fl-video-record
        [faceFilterType]="faceFilterType"
        [subContent]="subContent"
        [contentWhileRecording]="contentWhileRecording"
        (onError)="onError($event)"
      ></fl-video-record>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./video-record-modal.component.scss'],
})
export class VideoRecordModalComponent {
  HeadingType = HeadingType;
  TextSize = TextSize;

  @Input() modalHeader?: string;
  @Input() modalTitle?: string;
  @Input() content?: TemplateRef<HTMLElement>;
  @Input() subContent?: TemplateRef<HTMLElement>;

  @Input() contentWhileRecording?: TemplateRef<HTMLElement>;
  @Input() faceFilterType: FaceFilterType = 'none';

  constructor(
    private errorHandler: ErrorHandler,
    private modalRef: ModalRef<VideoRecordModalComponent>,
  ) {}

  onError(error: MediaDeviceErrorCode): void {
    if (error === MediaDeviceErrorCode.UNSUPPORTED_BROWSER) {
      this.modalRef.setError(
        new Error(
          `The specified MIME type is not supported by the user agent.`,
        ),
      );
    } else {
      const e = new Error(`Unknown error occured: ${error}`);
      this.errorHandler.handleError(e);
      this.modalRef.setError(e);
    }
  }
}
